import React, { useState, useMemo } from "react";
import { InstagramEmbed } from "react-social-media-embed";
import { instagramUrls } from "./urls";

function App() {
  const itemsPerPage = 5; // Number of posts to display per page

  // State for managing pagination
  const [currentPage, setCurrentPage] = useState(0);

  // Calculate the active posts to display for the current page
  const currentPosts = useMemo(() => {
    const startIdx = currentPage * itemsPerPage;
    const endIdx = startIdx + itemsPerPage;
    return instagramUrls.slice(startIdx, endIdx);
  }, [currentPage]); // Recalculate whenever `currentPage` changes

  // Total pages
  const totalPages = Math.ceil(instagramUrls.length / itemsPerPage);

  // Pagination Handlers
  const handlePrevPage = () => {
    if (currentPage > 0) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) setCurrentPage(currentPage + 1);
  };

  return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-900 text-white text-center px-4">
        <header>
          <h2 className="text-3xl font-bold mb-4">Apothecary Tattoo</h2>
          <p className="text-lg">578 Marsh St, San Luis Obispo, CA 93401</p>
          <p className="text-lg">(805) 752-1035</p>
          <p className="text-lg mb-4">jstockstell@live.com</p>
          <p className="mb-4">
            Call or email with questions or appointment requests
          </p>
          <h3 className="text-xl font-semibold mb-6">
            Sorry, our website is under construction! Please check back soon!
          </h3>
          <img
              src={`${process.env.PUBLIC_URL}/new_background.jpg`}
              alt="background"
              className="rounded-lg shadow-lg w-full max-w-md max-h-96 object-contain mx-auto"
          />

        </header>

        {/* Instagram Embed Section */}
        <section className="mt-8">
          <h3 className="text-2xl font-semibold mb-4">Recent Instagram Posts</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {/* Display current posts */}
            {currentPosts.map((url, index) => (
                <div
                    key={`${currentPage}-${index}`} // Force re-render via unique key
                    className="flex justify-center items-center bg-white rounded-lg shadow-md overflow-hidden"
                >
                  <InstagramEmbed url={url} width={328} captioned />
                </div>
            ))}
          </div>

          {/* Pagination Controls */}
          <div className="flex justify-between items-center mt-6">
            <button
                onClick={handlePrevPage}
                disabled={currentPage === 0}
                className={`px-4 py-2 bg-blue-600 rounded-lg text-white font-semibold disabled:opacity-50 ${
                    currentPage === 0 && "cursor-not-allowed"
                }`}
            >
              Previous
            </button>
            <span className="text-lg font-semibold">{`Page ${
                currentPage + 1
            } of ${totalPages}`}</span>
            <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages - 1}
                className={`px-4 py-2 bg-blue-600 rounded-lg text-white font-semibold disabled:opacity-50 ${
                    currentPage === totalPages - 1 && "cursor-not-allowed"
                }`}
            >
              Next
            </button>
          </div>
        </section>
      </div>
  );
}

export default App;