export const instagramUrls = [
    "https://www.instagram.com/artbyjake/p/DFB1UKayRtb/",
    "https://www.instagram.com/artbyjake/p/DEdA9poS0n-/",
    "https://www.instagram.com/artbyjake/p/DEQem94SSMF/",
    "https://www.instagram.com/artbyjake/p/DEI-bqmy6ej/",
    "https://www.instagram.com/artbyjake/p/DEAvG5zS7n_/",
    "https://www.instagram.com/artbyjake/p/DD00nwhxfRJ/",
    "https://www.instagram.com/artbyjake/p/DDiKjPxT3S_/",
    "https://www.instagram.com/artbyjake/p/DDfJwT3S-0t/",
    "https://www.instagram.com/artbyjake/p/DDdNePNPA60/",
    "https://www.instagram.com/artbyjake/p/DDZ_tlKvX0f/",
    "https://www.instagram.com/artbyjake/p/DDX45DDTD8W/",
    "https://www.instagram.com/artbyjake/p/DDU64C9yD5Y/",
    "https://www.instagram.com/artbyjake/p/DDAfEo7yt6i/",
    "https://www.instagram.com/artbyjake/p/DCw_qKOz9Om/",
    "https://www.instagram.com/artbyjake/p/DCpB_BTyuC1/",
    "https://www.instagram.com/artbyjake/p/DCZvHnESwpP/",
    "https://www.instagram.com/artbyjake/p/DB9axlzS8jG/",
    "https://www.instagram.com/artbyjake/p/DBwWHL2vfQ3/",
    "https://www.instagram.com/artbyjake/p/C_QvMdVvNd1/",
    "https://www.instagram.com/artbyjake/p/C1abRPuPo2X/",
    "https://www.instagram.com/artbyjake/p/C1FQYG9xLLo/",
    "https://www.instagram.com/artbyjake/p/C1DMCy4S1FK/",
    "https://www.instagram.com/artbyjake/p/C0-k2ydLLzU/",
    "https://www.instagram.com/artbyjake/p/C04ZJUQLBjB/",
    "https://www.instagram.com/artbyjake/p/C00NMcZL4ex/",
    "https://www.instagram.com/artbyjake/p/C0uL1uJRoSI/",
    "https://www.instagram.com/artbyjake/p/C0clX1oS5I8/",
    "https://www.instagram.com/artbyjake/p/C0E6L6WRsxr/",
    "https://www.instagram.com/artbyjake/p/Cz6e618Lwmh/",
    "https://www.instagram.com/artbyjake/p/CzcOwUtvdlg/",
    "https://www.instagram.com/artbyjake/p/CzZ-0RBxCGq/",
    "https://www.instagram.com/artbyjake/p/CzEovyrPkBL/",
    "https://www.instagram.com/artbyjake/p/Cyto89WvD8n/",
    "https://www.instagram.com/artbyjake/p/CyV83oFrSud/",
    "https://www.instagram.com/artbyjake/p/Cx-_D__x-QM/",
    "https://www.instagram.com/artbyjake/p/Cx8_kZ7vLZF/",
    "https://www.instagram.com/artbyjake/p/Cw3-lzWrD2W/",
    "https://www.instagram.com/artbyjake/p/CwWE_Ycv4QI/",
    "https://www.instagram.com/artbyjake/p/Cv5JH1KxEAb/",
    "https://www.instagram.com/artbyjake/p/CuXRFLfR1Tm/",
    "https://www.instagram.com/artbyjake/p/CtcFDitpFQq/",
    "https://www.instagram.com/artbyjake/p/CsSJFjivf-9/",
    "https://www.instagram.com/artbyjake/p/CsKFB1Hy_fQ/",
    "https://www.instagram.com/artbyjake/p/CsB8dEMpvuF/",
    "https://www.instagram.com/artbyjake/p/Cr1coBsveiJ/"
];